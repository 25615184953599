import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Title2 } from './v2/StyledComponents';
import { colors, mobileThresholdPixels } from '../styledComponents';

const Container = styled.div`
  padding: 20px 0px 40px;
  background-color: ${props => props.greyBackground ? colors.lightGrey : colors.white};
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 1px 0px;
  }
`;

const P = styled.p`
  color: ${colors.navy};
  text-align: center;
  font-size: 18px;
`;

const BrandFooter = ({ greyBackground }) => (
  <Container id="BrandFooter" greyBackground={greyBackground}>
    <Title2>une question ?</Title2>
    <P>Contactez-nous : contact@tilli.fr | 06.82.33.28.70 </P>
    <P>Pour rappel, les villes couvertes par Tilli sont Paris (et 1ère/2ème courronne), Lyon,
      Marseille, Aix-en-Provence et Metz.</P>
  </Container>
);

BrandFooter.propTypes = {
  greyBackground: PropTypes.bool,
};

BrandFooter.defaultProps = {
  greyBackground: true,
};

export default BrandFooter;
