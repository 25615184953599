import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { ViewPager, Frame, Track, View } from 'react-view-pager';

import routesMap from '../../Routes';
import { trackEvent } from '../../services/analytics';
import isMobile from '../../services/isMobile';
import Dots from '../../components/Home/v2/Dots';
import {
  colors, margins, fontSizes, fontWeight, mobileThresholdPixels,
  Title2, Subtitle2, Button, ButtonContainer, ContainerBlock, Row, Column, LineV,
} from '../../components/Home/v2/StyledComponents';

import arrowLeft from '../../assets/theyLoveTilli/arrow.left.navy.svg';
import arrowRight from '../../assets/theyLoveTilli/arrow.right.navy.svg';

const textStyle = {
  fontSize: fontSizes.s,
  fontFamily: 'Roboto',
};

const feedbacks = [
  {
    index: 0,
    author: 'Guillaume',
    text: (
      <span style={textStyle}>
        De très bons conseils, du travail de grande qualité et rapide, <br />bravo !
      </span>
    ),
  },
  {
    index: 1,
    author: 'Miguel',
    text: (
      <span style={textStyle}>
      Le concept est vraiment top et les prix très attractifs comparés aux tailleurs/retouches habituels !
      Vive Tilli ! 😉
      </span>
    ),
  },
  {
    index: 2,
    author: 'Eleonore',
    text: (
      <span style={textStyle}>
        Canon <br />
        {'Et c\'est jouissif de transformer des fringues qui hibernaient dans le placard !!!' }
      </span>
    ),
  },
  {
    index: 3,
    author: 'Julien',
    text: (
      <span style={textStyle}>
      Trop sympa la fille ! <br />{'Bravo c\'est top top top'}
      </span>
    ),
  },
  {
    index: 4,
    author: 'Maria',
    text: (
      <span style={textStyle}>
        Brilliant service, pratique, rapide, top professionnal quality, équipe plus que compétente et sympa.
        Bravo Tilli !!!!! 20/20 !!!
      </span>
    ),
  },
];

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${margins.m} 0px ${margins.xl} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 0px;
  }
`;

const ControlContainer = styled.div`
  z-index: 1;
  width: 2%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 6%;
  }
`;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  padding: 18px 20px 14px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 18px 10px 14px;
    margin: ${margins.m} 0px;
  }
`;

const FeedbackContainer = styled.div`
  margin: 0px ${margins.m};
`;

const frameStyle = {
  width: '100%',
};

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
};

const AuthorNameSpan = styled.span`
  display: flex;
  align-items: center;
  font-size: ${fontSizes.s};
  font-weight: bold;
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const TextContainer = styled.div`
  font-weight: ${fontWeight.medium};
`;

const feedbackDotSize = 9;
const FeedbackDot = styled.span`
  margin-top: 2px;
  background-color: ${colors.navy};
  width: ${feedbackDotSize}px;
  height: ${feedbackDotSize}px;
  border-radius: 50%;
  margin-left: ${margins.xs};
`;

class TheyLoveTilli extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: 0,
      nextView: 1,
    };
  }

  render() {
    return (
      <ContainerBlock>
        <Column justifyContent={'center'} alignItems={'center'}>
          <Title2>Témoignages</Title2>
          <Dots />
          <Subtitle2>Ils ont essayé,<br />ils ont aimé.</Subtitle2>
        </Column>
        <SubContainer>
          <ViewPager tag="main" style={viewPagerStyle}>
            <ControlContainer right>
              <ControlImg
                alt="Flèche précédente pour tous types de retouches"
                src={arrowLeft} onClick={() => this.track.prev()}
              />
            </ControlContainer>
            <Frame className="frame" style={frameStyle}>
              <Track
                ref={(c) => { this.track = c; }}
                viewsToShow={isMobile() ? 1 : 3}
                infinite
                onViewChange={() =>
                  this.setState({
                    currentView: (this.track.context.pager.currentView.index),
                    nextView: (this.track.context.pager.currentView.index + 1) % feedbacks.length,
                  })}
                className="track"
              >
                {feedbacks.map(feedback =>
                  <View className="view" key={feedback.index}>
                    <Row justifyContent={'space-between'} alignContent={'center'} alignItems={'flex-start'}>
                      <FeedbackContainer>
                        <AuthorNameSpan>
                          {feedback.author}{' '}
                          <FeedbackDot />
                          <FeedbackDot />
                          <FeedbackDot />
                          <FeedbackDot />
                          <FeedbackDot />
                        </AuthorNameSpan>
                        <TextContainer>{feedback.text}</TextContainer>
                      </FeedbackContainer>
                      { !isMobile() &&
                        (this.state.currentView === feedback.index || this.state.nextView === feedback.index)
                          &&
                          <LineV height={'100px'} />
                      }
                    </Row>
                  </View>,
                )}
              </Track>
            </Frame>
            <ControlContainer right>
              <ControlImg
                alt="Flèche suivante de boutique retoucherie"
                src={arrowRight} onClick={() => this.track.next()}
              />
            </ControlContainer>
          </ViewPager>
          <Link
            to={routesMap.Step1.url}
            onClick={() => trackEvent('click', 'faites-comme-eux_block-ils-ont-aime')}
          >
            <ButtonContainer margin={!isMobile() ? undefined : `${margins.l} 0px`}>
              <StyledButton>Faites comme eux et essayez Tilli !</StyledButton>
            </ButtonContainer>
          </Link>
        </SubContainer>
      </ContainerBlock>
    );
  }
}

export default TheyLoveTilli;
